<template>
  <div class="select__Wrapper" :class="{isOpen}" v-click-outside="() => {close()}">
    <span v-text="selectText" @click="toggleVisiblity"/>
    <ul>
      <li v-for="item in options" :key="item.id">
        <input type="checkbox" :id="item.value" :value="item.value" v-model="selectValue">
        <label :for="item.value" v-text="item.text"/>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'
import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

export default {
  props: {
    value: {},
    options: {},
    placeholder: {}
  },

  data(){
    return{
      isOpen: false
    }
  },

  computed: {
    selectValue: {
      get(){
        return this.value
      },

      set(value){
        this.$emit('input', value)
      }
    },

    selectText(){
      return this.selectValue.length > 0 ? this.selectValue.join(', ') : this.placeholder
    }
  },

  methods: {
    toggleVisiblity(){
      this.isOpen = !this.isOpen
    },

    close(){
      this.isOpen = false;
    }
  }
}
</script>

<style lang="scss">

.select__Wrapper{
  position: relative;
  font-size: 16px;
  border-bottom: 1px solid #e8e8e8;
  max-width: 334px;
  @media screen and (max-width: 768px) {
    max-width: none;
  }
  transition: border-color 300ms;
  cursor: pointer;
  &.isOpen{
    z-index: 100010;
    border-color: transparent;
    ul{
      opacity: 1;
      visibility: visible;
    }
    span:after{
      transform: translateY(-20%) rotate(225deg);
    }
  }

  span{
    font-weight: bold;
    padding: 8px 0;
    display: block;
  }

  ul{
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: 8px 0;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    width: 100%;
    transition: opacity 300ms, visibility 300ms;
  }

  li{
    overflow: hidden;
    cursor: pointer;
    transition: background-color 300ms;
    &:hover{
      background-color: #e8e8e8;
    }

    input{
      position: absolute;
      left: -99999px;
      &:checked{
        & + label:before{
          background-color: #e3010f;
          background-image: url('../assets/icon-checkbox.svg');
          background-size: 8px 8px;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
    }

    label{
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      padding: 8px 16px;
      &:before{
        content: "";
        display: block;
        min-width: 12px;
        min-height: 12px;
        max-width: 12px;
        max-height: 12px;
        border-radius: 3px;
        border: 1px solid #e3010f;
        margin: 3px 12px 0 0;
      }
    }
  }
}
</style>
<template>
  <div class="home" v-if="isReady">
		<h1>Pronađi trgovine</h1>
    <div class="locator">
			<div class="search">
        <input
          ref="search"
          placeholder="Grad, ulica, poštanski broj…"
          v-model="search"
          @keydown.enter="activateSearch"
        >
        <button @click="activateSearch">Pretraži</button>
			</div>
			<button @click="getLocation(showPosition)">
				<img src="../assets/locate.svg" alt="">
				Pronađi trgovine u blizini
			</button>
		</div>

		<ul class="filters">
			<li v-for="(item, key) in topFilters" :key="item.key">
    		<button @click="filter(key)" :class="{ isActive: activeFilter === key }" v-html="item"/>
			</li>
			<li v-for="item in holidays" :key="item.id">
				 <button @click="filter(item)" v-text="`Otvoreno na ${item}`" :class="{ isActive: activeFilter === item }"/>
			</li>
		</ul>

    <SelectField
      :options="types"
      placeholder="Filtriraj"
      v-model="selectedTypes"
    />

    <div class="map__Wrapper">
      <GmapMap
				ref="gmap"
        :center="center"
        :zoom="zoom"
        :options="{ styles, streetViewControl: false, mapTypeControl: false, fullscreenControl: false, restriction: bounds}"
      >
        <GmapCluster :gridSize="51" :styles="clusterStyles" ref="cluster" @click="clusterClick">
          <GmapMarker
            v-for="item in locations"
            :key="item.id"
            :position="item.coordinates"
            :clickable="true"
            :animation="4"
            :icon="icons[item.type[0]] || icons['MALI']"
            :optimized="false"
            @click="setActiveLocation(item, true, true)"
          >
          </GmapMarker>
        </GmapCluster>
      </GmapMap>

      <div class="map__Info" v-if="activeLocation">
				<button @click="setActiveLocation(undefined)" class="btn__Close"><img src="../assets/x.svg" alt=""></button>
				<h3 v-text="getLocationTitle"/>
        {{ activeLocation.address }}<br>
        {{ activeLocation.phone_number }}<br>
        <div>
          <button @click="toggleWorkingHours" class="btn__WorkingHours" :class="{workingHoursVisible, isClosed: !activeLocation.is_open}">{{ getOpenText }}</button>
          <span class="spn__ClosedText">{{ getClosedText }}</span>
        </div>
				<table v-if="workingHoursVisible">
					<tr v-for="(item, index) in activeLocation.work_hours" :key="item.id">
            <template v-if="index == 0">
              <td v-html="item.name" class="td__SemiBold"/>
              <td v-html="getWorkingHours(item)" class="td__SemiBold"/>
            </template>
            <template v-else>
              <td v-html="item.name"/>
              <td v-html="getWorkingHours(item)"/>
            </template>
					</tr>
          <tr colspan="2" class="table__Description">*Radno vrijeme narednih 7 dana</tr>
				</table>
        <button @click="getLocation(openDirections)" class="btn__Directions">
          <svg width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
            <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
              <path d="M10.625 9.75 15 5.375 10.625 1"/>
              <path d="M1 13V8.875a3.5 3.5 0 0 1 3.5-3.5H15"/>
            </g>
          </svg> Upute
        </button>
        <p>Ne propusti akcije, <a :href="catalog" target="_blank">pogledaj katalog</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import SelectField from "@/components/SelectField";
import icons from "@/assets/icons";
import styles from "@/assets/styles";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
Vue.component("GmapCluster", GmapCluster);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCfO1jvDkcSl6jAbrXQfAw3Nm-OFuxeIqM",
		libraries: 'places',
  },
});

export default {
  name: "Home",
  data() {
    return {
      autocomplete: undefined,
      places: undefined,
      types: [],
      locationStoreTypes: ['MAXI', 'SUPER', 'MALI', 'BENZ', 'SMART'],
			zoom: 7,
      icons,
      bounds: {
        latLngBounds: {
          north: 46.55 + 0.2,
          south: 42.383333 - 0.2,
          east: 19.45 + 0.2,
          west: 13.5 - 0.2,
        }
      },
      selectedTypes: [],
      selectedHoliday: undefined,
      activeFilter: undefined,
			workingHoursVisible: false,
			search: '',
      clusterStyles: [{
        url: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzciIHZpZXdCb3g9IjAgMCAzNyAzNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4LjUgMzdDMjguNzE3MyAzNyAzNyAyOC43MTczIDM3IDE4LjVDMzcgOC4yODI3MyAyOC43MTczIDAgMTguNSAwQzguMjgyNzMgMCAwIDguMjgyNzMgMCAxOC41QzAgMjguNzE3MyA4LjI4MjczIDM3IDE4LjUgMzdaIiBmaWxsPSIjRkY5NDk0IiBmaWxsLW9wYWNpdHk9IjAuNzMxNDE1Ii8+CjxwYXRoIGQ9Ik0xOC41IDMzLjVDMjYuNzg0MyAzMy41IDMzLjUgMjYuNzg0MyAzMy41IDE4LjVDMzMuNSAxMC4yMTU3IDI2Ljc4NDMgMy41IDE4LjUgMy41QzEwLjIxNTcgMy41IDMuNTAwMDIgMTAuMjE1NyAzLjUwMDAyIDE4LjVDMy41MDAwMiAyNi43ODQzIDEwLjIxNTcgMzMuNSAxOC41IDMzLjVaIiBmaWxsPSIjRkYwMDAwIi8+Cjwvc3ZnPgo=",
        width: 37,
        height: 37,
        textColor: "#fff"
      },
      {
        url: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNDUiIHZpZXdCb3g9IjAgMCA0NSA0NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyLjUgNDVDMzQuOTI2NCA0NSA0NSAzNC45MjY0IDQ1IDIyLjVDNDUgMTAuMDczNiAzNC45MjY0IDAgMjIuNSAwQzEwLjA3MzYgMCAwIDEwLjA3MzYgMCAyMi41QzAgMzQuOTI2NCAxMC4wNzM2IDQ1IDIyLjUgNDVaIiBmaWxsPSIjRkY5NDk0IiBmaWxsLW9wYWNpdHk9IjAuNzMxNDE1Ii8+CjxwYXRoIGQ9Ik0yMi41IDQwLjc0MzJDMzIuNTc1NSA0MC43NDMyIDQwLjc0MzMgMzIuNTc1NSA0MC43NDMzIDIyLjVDNDAuNzQzMyAxMi40MjQ1IDMyLjU3NTUgNC4yNTY3NiAyMi41IDQuMjU2NzZDMTIuNDI0NiA0LjI1Njc2IDQuMjU2NzcgMTIuNDI0NSA0LjI1Njc3IDIyLjVDNC4yNTY3NyAzMi41NzU1IDEyLjQyNDYgNDAuNzQzMiAyMi41IDQwLjc0MzJaIiBmaWxsPSIjRkYwMDAwIi8+Cjwvc3ZnPgo=",
        width: 45,
        height: 45,
        textColor: "#fff"
      },
      {
        url: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA1MiA1MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI2IDUyQzQwLjM1OTQgNTIgNTIgNDAuMzU5NCA1MiAyNkM1MiAxMS42NDA2IDQwLjM1OTQgMCAyNiAwQzExLjY0MDYgMCAwIDExLjY0MDYgMCAyNkMwIDQwLjM1OTQgMTEuNjQwNiA1MiAyNiA1MloiIGZpbGw9IiNGRjk0OTQiIGZpbGwtb3BhY2l0eT0iMC43MzE0MTUiLz4KPHBhdGggZD0iTTI2IDQ3LjA4MTFDMzcuNjQyOCA0Ny4wODExIDQ3LjA4MTEgMzcuNjQyOCA0Ny4wODExIDI2QzQ3LjA4MTEgMTQuMzU3MiAzNy42NDI4IDQuOTE4OTEgMjYgNC45MTg5MUMxNC4zNTczIDQuOTE4OTEgNC45MTg5NSAxNC4zNTcyIDQuOTE4OTUgMjZDNC45MTg5NSAzNy42NDI4IDE0LjM1NzMgNDcuMDgxMSAyNiA0Ny4wODExWiIgZmlsbD0iI0ZGMDAwMCIvPgo8L3N2Zz4K",
        width: 52,
        height: 52,
        textColor: "#fff"
      }],
			topFilters: {
				is_open: 'Otvoreno sada',
				open_this_sunday: 'Otvoreno ovu nedjelju'
			},
      styles,
      markers: [],
      holidays: [],
      catalog: '',
      center: { lat: 45, lng: 17 },
      activeLocation: undefined,
			currentLocation: undefined,
			isReady: false,
    };
  },

  components: {
    SelectField,
  },

  async mounted() {
    try {
      const {
        data: { locations, location_types, holidays, catalog },
      } = await axios.get("https://trgovine.konzum.hr/api/locations/");
      this.markers = locations.map((i, ind) => {
        const work_hours = JSON.parse(i.work_hours);
        const holidays = JSON.parse(i.holidays);
        const now = new Date();
        const weekday = ["Ned", "Pon", "Uto", "Sri", "Čet", "Pet", "Sub"][now.getDay()]
        for (let i = 0; i < 10; i++) {
          if (weekday != work_hours[0].short_name) {
            if (holidays.includes(work_hours[0].name)) {
              holidays.splice(holidays.indexOf(work_hours[0].name), 1);
            }
            work_hours.shift();
          } else {
            while (work_hours.length > 7) {
              if (holidays.includes(work_hours[work_hours.length - 1].name)) {
                holidays.splice(holidays.indexOf(work_hours[work_hours.length - 1].name), 1);
              }
              work_hours.pop();
            }
            break;
          }
        }
        const from = new Date(work_hours[0].from_hour);
        // if(ind%2 === 0) {
        //   from.setHours(10)
        //   from.setMinutes(10)
        //   work_hours[0].from_hour = from;
        // }
        const is_open = new Date(from) < now && new Date(work_hours[0].to_hour) > now;
        return {...i, is_open, work_hours, holidays }
      });
      // console.log(this.markers)
      this.holidays = holidays.filter(i => {
        const date = new Date(i.date);
        const now = new Date();
        return date > now && date < new Date(now.setDate(now.getDate() + 7));
      }).map(i => i.name);
      for (const key in location_types) {
        this.types.push({ value: key, text: location_types[key] });
      }
      this.catalog = catalog ? catalog : "#";
    } catch (e) {
      console.dir(e);
    }

    if(window.innerWidth <= 768) this.zoom = 5

		this.isReady = true;

		this.$nextTick(async () => {
			// let event = new CustomEvent('locationsReady', { detail: `${this.$el.scrollHeight}px` })
			// window.parent.document.dispatchEvent(event)
      // parent.postMessage({height: this.$el.scrollHeight}, "*")
      const height = this.$el.scrollHeight + 20;
      parent.postMessage({height: height}, "*")
			document.documentElement.style.minHeight = `${height}px`
			document.documentElement.style.overflow = 'hidden'
      // this.$refs.cluster.minimumClusterSize = 100

      const google = await this.$refs.gmap.$gmapApiPromiseLazy()
      this.autocomplete = new google.maps.places.AutocompleteService();
      this.places = new google.maps.places.PlacesService(this.$refs.gmap.$mapObject);
		})
  },

  computed: {
    locations() {
      let locations = JSON.parse(JSON.stringify(this.markers));
      if (this.activeFilter !== undefined) {
        locations = locations.filter(
          i => i[this.activeFilter] || i.holidays.includes(this.activeFilter)
        );
      }

      if (this.selectedTypes.length > 0) {
        // we need to show any type of store that contains all of the selected services
        let selectedLocationTypes = this.selectedTypes.filter(i => 
          this.locationStoreTypes.includes(i)
        );
        let selectedServiceTypes = this.selectedTypes.filter(i => 
          !this.locationStoreTypes.includes(i)
        );

        locations = locations.filter(i => 
          selectedLocationTypes.length === 0 || selectedLocationTypes.some(type => i.type.includes(type))
        ).filter(i => 
          selectedServiceTypes.every(type => i.type.includes(type))
        );
			}
			
			// if(this.search !== ''){
			// 	locations = locations.filter(i => i.address.toLowerCase().includes(this.search.toLowerCase()) || i.type.join(',').toLowerCase().includes(this.search.toLowerCase()))
			// }

      return locations;
    },

    getLocationTitle(){
      if(this.activeLocation) return this.types.find(i => i.value === this.activeLocation.type[0]).text
      return ''
    },

    getOpenText(){
      let text = this.activeLocation.is_open ? 'Otvoreno' : 'Zatvoreno';
      if (this.activeLocation.is_open && !this.workingHoursVisible) text += ` do ${this.getHours(this.activeLocation.work_hours[0]['to_hour'], 'to')}`;
      return text;
    },

    getClosedText(){
      if (!this.activeLocation.is_open && !this.workingHoursVisible) {
        let start = new Date() < new Date(this.activeLocation.work_hours[0]['from_hour']) ? 0 : 1;
        for (let i = start; i < 7; i++) {
          let from = this.activeLocation.work_hours[i]['from_hour'];
          if (from) {
            return `Otvara se u ${this.activeLocation.work_hours[i]['short_name']} u ${this.getHours(from, 'from')}`;
          }
        }
      }
      return '';
    }
  },

  methods: {
    filter(key) {
      this.activeFilter = this.activeFilter === key ? undefined : key;
			// if(['', undefined].includes(this.search)) this.fitMarkers()
    },

		fitMarkers(){
			let bounds = new window.google.maps.LatLngBounds();
      // if no markers are shown, zoom to default bounds
      if(this.locations.length > 0) {
        for (let i = 0; i < this.locations.length; i++) {
				  bounds.extend(this.locations[i].coordinates);
			  }
      }
			else {
        bounds = this.bounds.latLngBounds
      }
      this.panToBounds(bounds)
		},

    clusterClick(e){
      this.panToBounds(e.bounds_)
    },

    panToBounds(bounds){
      this.$refs.gmap.$mapObject.fitBounds(bounds)
      setTimeout(() => {
        this.$refs.gmap.$mapObject.fitBounds(bounds)
      }, 1);
    },

    setActiveLocation(location, popup=true, fromMarker=false) {
      if(location) {
        const newLocation = JSON.parse(JSON.stringify(location))
      	if(popup) this.activeLocation = location;
        else this.activeLocation = undefined;
        if(fromMarker){
          if(window.innerWidth > 768){
            newLocation.coordinates.lng = newLocation.coordinates.lng+0.005
          } else {
            newLocation.coordinates.lat = newLocation.coordinates.lat-0.001
          }
        }
        this.goToLocation(newLocation)
			} else {
				this.activeLocation = undefined;
				this.workingHoursVisible = false;
			}
    },

		getHours(date, type){
      if(!date) return undefined;
			date = new Date(date);
      let time = date.toLocaleTimeString('hr', { hour: '2-digit', minute: '2-digit' });
      if (type == 'from') {
        return time;
      }
			return time === '00:00' ? '24:00' : time;
		},

		getWorkingHours(item){
      if(item.from_hour && item.to_hour) return `${this.getHours(item.from_hour, 'from')} - ${this.getHours(item.to_hour, 'to')}`
      return 'Zatvoreno'
    },

		goToLocation(item, zoom=17) {
			this.zoom = zoom
			this.$refs.gmap.$mapObject.panTo(item.coordinates)
			setTimeout(() => {
        this.$nextTick(()=>{
          // strange fix
				  this.zoom = zoom-1;
			    this.$refs.gmap.$mapObject.panTo(item.coordinates)
        })
			}, 100);
		},

		toggleWorkingHours(){
			this.workingHoursVisible = !this.workingHoursVisible;
		},

		getLocation(success){
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(success);
			} else {
				alert()
			}
		},

		rad(x) {return x*Math.PI/180;},

		showPosition({coords: {latitude: lat, longitude: lng}}){
			const R = 6371;
			let distances = [];
			let closest = -1;
			for(let i = 0; i < this.locations.length; i++) {
				let mlat = this.locations[i].coordinates.lat;
				let mlng = this.locations[i].coordinates.lng;
				let dLat  = this.rad(mlat - lat);
				let dLong = this.rad(mlng - lng);
				let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
						Math.cos(this.rad(lat)) * Math.cos(this.rad(lat)) * Math.sin(dLong/2) * Math.sin(dLong/2);
				let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
				let d = R * c;
				distances[i] = d;
				if ( closest == -1 || d < distances[closest] ) closest = i;
			}
			if(closest !== -1) this.setActiveLocation(this.locations[closest], true, true);
		},

    openDirections({coords: {latitude: lat, longitude: lng}}){
			if (this.activeLocation) {
        window.open(`https://www.google.com/maps/dir/?api=1&origin=${lat},${lng}&destination=${this.activeLocation.coordinates.lat},${this.activeLocation.coordinates.lng}`)
      }
		},

		// enter(){
		// 	// this.locations = this.locations.filter(i => i.address.toLowerCase().includes(this.search.toLowerCase()));
		// 	this.fitMarkers()
		// },

		closeSearch(){
			this.search = '';
		},

    goToPlace(e){
      if(e.geometry){
        const coordinates = {
          lat: e.geometry.location.lat(),
          lng: e.geometry.location.lng(),
        };
        this.goToLocation({coordinates})
      }
    },

    activateSearch(){
      const request = {
        input: this.search,
        componentRestrictions: {country: 'hr'},
      };

      this.autocomplete.getPlacePredictions(request, (predictions) => {
        if(predictions && predictions.length > 0){
          this.places.getDetails({placeId: predictions[0].place_id, fields: ['geometry', 'formatted_address', 'types']}, (place) => {
            if(place){
              this.search = place.formatted_address;
              const coordinates = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              this.goToLocation({coordinates}, place.types && (place.types.includes('premise') || place.types.includes('street_address')) ? 18 : (place.types.includes('route') || place.types.includes('neighborhood')) ? 16 : 13)
            }
          })
        }
      });
      this.activeLocation = undefined;
    }
  },

	watch: {
		selectedTypes(){
			// this.fitMarkers()
		}
	}
};
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

$white: #fff;
$green: #38aa34;
$red: #e3010f;

*{
	box-sizing: border-box;
}

body{
  margin: 0;
}

.vue-map-container{
  min-height: 600px;
  width: 100%;
  @media screen and (max-width: 768px) {
    min-height: 450px;
  }
}

.home{
  padding: 0 29px;
  font-family: 'Open Sans', sans-serif;
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }

	h1{
    font-size: 44px;
    font-weight: 700;
    margin: 0 0 16px;
		padding-top: 74px;
    @media screen and (max-width: 768px) {
      font-size: 24px;
      padding-top: 24px;
    }
  }
}

.locator{
  display: flex;
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  button{
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: $green;
    font-size: 16px;
    font-weight: bold;
    &:hover{
      img{
        transform: rotate(90deg);
      }
    }

    @media screen and (max-width: 768px) {
      margin-left: 0;
      margin-top: 12px;
      font-size: 12px;
    }

    img{
      margin-right: 12px;
      transition: transform 300ms;
      @media screen and (max-width: 768px) {
        max-width: 22px;
        margin-right: 8px;
      }
    }
  }
}

.search{
	position: relative;
	max-width: 770px;
  width: 100%;
  margin-right: 32px;
	z-index: 10000;
  &.hasValue{
    z-index: 1000000;
  }
  
	input{
		background-color: #f7f7f7;
		border: 1px solid transparent;
		border-radius: 5px;
		padding: 21px 56px 21px 16px;
		font-size: 16px;
		color: #2c2c2c;
		outline: 0;
		width: 100%;
		&::placeholder{
			color: #8d8d8d;
		}
		@media screen and (max-width: 1124px) {
			margin-right: 12px;
		}
		@media screen and (max-width: 768px) {
			font-size: 14px;
			background-size: 24px 24px;
		}
	}
  
  button{
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
    min-width: 32px;
    min-height: 32px;
    font-size: 0;
    background-image: url("../assets/magnifier.svg");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 32px 32px;
    transition: 300ms;
    margin-top: 0;
    &:hover{
      transform: translate(0, -50%) scale(1.1);
    }
  }

	ul{
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: 8px 0;
    background-color: #fff;
    width: 100%;
		max-height: 400px;
		overflow: hidden;
		overflow-y: auto;
    @media screen and (max-width: 768px) {
      max-height: 300px;
    }
  }

  li{
    overflow: hidden;
    cursor: pointer;
    transition: background-color 300ms;
		padding: 8px;
    &:hover{
      background-color: #e8e8e8;
    }
	}
}

ul{
	list-style: none;
	padding: 0;
	margin: 0;
}

.filters{
	display: flex;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    margin-top: 16px;
  }

  button{
    font-size: 12px;
    font-weight: 600;
    padding: 17px 16px 15px 17px;
    margin: 16px 0 32px;
    margin-right: 16px;
    background-color: $white;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
		cursor: pointer;
    color: #000;
		transition: background-color 300ms, color 300ms;
    @media screen and (max-width: 768px){
      margin-top: 0;
      margin-bottom: 8px;
      margin-right: 8px;
			padding: 8px 12px;
    }

		&.isActive {
			background-color: $red !important;
			color: $white;
		}
  }
}

.select__Wrapper{
  position: relative;
  margin-bottom: 15px;
  border-bottom: 1px solid #e9e9e9;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 8px;
  }
  span{
    &::after{
      position: absolute;
      content: "";
      height: 8px;
      width: 8px;
      top: 50%;
      right: 10px;
      border-bottom: 2px solid $red;
      border-right: 2px solid $red;
      transform: translateY(-50%) rotate(45deg);
      transition: transform 300ms;
    }
  }
}

.map__Wrapper{
	position: relative;
}

.map__Info{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: $white;
	padding: 30px 24px 14px 24px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: solid 1px #ebebeb;
  font-size: 14px;
  line-height: 1.71;
  color: #565656;
  width: 100%;
  max-width: 330px;
  @media screen and (max-width: 460px){
		position: fixed;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    transform: none;
    max-width: none;
  }

  button{
    cursor: pointer;
  }

  h3{
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    color: #2c2c2c;
    margin: 0 0 10px;
  }

  p{
    border-top: 1px solid #ebebeb;
    padding: 12px 0 0;
    margin-top: 16px;
    margin: 16px auto 0;
    text-align: center;
    a{
      font-weight: 600;
      color: $green;
      text-decoration: none;
    }
  }
}

table{
  font-size: 14px;
  line-height: 1.71;
  color: #565656;
  @media screen and (max-width: 425px){
    padding-right: 0;
  }

  tr{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
    td:first-of-type{
      padding-right: 45px;
    }
  }
}

.table__Description{
  font-size: 12px;
  color: #565656;
  margin-top: 10px;
}

.btn__Close{
  padding: 0;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 26px;
  border: none;
  background-color: transparent;
}

.btn__WorkingHours{
  position: relative;
  border: none;
  border-radius: 6px;
  display: inline-block;
  color: $green;
  background-color: #f3faf3;
  padding: 10px 26px 10px 8px;
  margin: 16px 0;
    &:after{
      position: absolute;
      content: "";
      height: 5px;
      width: 5px;
      top: 50%;
      right: 10px;
      border-bottom: 1px solid $green;
      border-right: 1px solid $green;
      transform: translateY(-50%) rotate(45deg);
      transition: transform 300ms;
    }
  &.workingHoursVisible{
    &:after{
      transform: translateY(-20%) rotate(225deg);
    }
  }
}

  .btn__WorkingHours.isClosed{
    color: $red;
    background-color: #fef2f2;
      &:after{
        border-bottom: 1px solid $red;
        border-right: 1px solid $red;
      }
  }

.btn__Directions{
  display: inline-block;
  border: 2px solid $red;
  border-radius: 8px;
  background-color: transparent;
  padding: 13px 16px;
  font-size: 16px;
  font-weight: 600;
  color: $red;
  margin-top: 12px;
	cursor: pointer;
	text-decoration: none;
	transition: color 300ms, background-color 300ms;
  &:hover{
    color: #f3faf3;
    background-color: $red;
    // border: 2px solid #f3faf3;
  }

  svg{
    margin-right: 13px;
		transition: fill 300ms;
    &:hover{
      fill: #fff;
    }
  }
}

.spn__ClosedText {
  margin-left: 12px;
}

.td__SemiBold {
  font-weight: 600;
}

.pac-container{
  display: none !important;
}

</style>
<template>
<div>
  <h1>Page s iframeom</h1>
  <iframe :style="`width: 100%; min-height: ${height}`" src="https://trgovine.konzum.hr/" frameborder="0"/>
</div>
</template>


<script>

export default {
  data(){
    return{
      height: 0
    }
  },

  mounted(){
    let eventMethod = window.addEventListener
			? "addEventListener"
			: "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent"
      ? "onmessage"
      : "message";

    eventer(messageEvent, (e) => {
      if (e.data.height !== undefined){
        document.querySelector('iframe').style.minHeight = `${e.data.height}px`
      }
    });
  },

  methods: {
    
  }
}
</script>

<style>

</style>